import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ECode } from "~/_enums/ECode";
import { env } from "~/env";
import { DesktopCommunityJoinModal } from "~/modules/Router/Auth/components/DesktopCommunityJoinModal";
import { getIsJoinedDao } from "~/modules/Router/Auth/utils/join/getIsJoinedDao";
import { useAppDispatch } from "~/store";
import { setGlobalMode } from "~/store/globalSlice";
import { setMarketplaceVisibility } from "~/store/market/slice";
import { Error as ErrorPage } from "../../../../router/routes";
import { AuthDispatch } from "../contexts/AuthDispatchContext";
import { AuthState } from "../contexts/AuthStateContext";
import { usePathRef } from "../hooks";
import { Initializing } from "./index";
import { MainModule } from "./MainModule";
import { TDao } from "../../../../_types/TDao";
import { TMember } from "../../../../_types/TMember";
import { postCommonUser } from "~/_api/users/postCommonUser";
import { useNavigate } from "react-router-dom";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { useUserStore } from "~/_store/users/useUserAtomStore";
import i18next from "i18next";

function AuthGuard({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation([ENameSpace.Common]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    decodedPath2Ref,
    isLoginPath,
    isAuthExcludedPath,
    isNotFoundPath,
    isErrorUrlRef,
  } = usePathRef();

  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isDaoLoading, setIsDaoLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isGating, setIsGating] = useState(false);
  const [dao, setDao] = useState<TDao | null>(null);
  const [member, setMember] = useState<TMember | null>(null);
  const { setUser } = useUserStore();

  // user 정보
  const [authState, setAuthState] = useState<AuthState>({
    userId: ``,
    address: ``,
    daoId: ``,
    isEmailAccount: false,
    categoryId: "",
    accounts: null,
    needWalletConnect: false,
    subdomain: ``,
  });
  const [openCommunityJoinModal, setOpenCommunityJoinModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseCommunityJoinModal = () => {
    setOpenCommunityJoinModal(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpenCommunityJoinModal = () => {
    setOpenCommunityJoinModal(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAuthState = async () => {
    const result = await postCommonUser({});

    if (result.code === ECode.Success && result.data.id) {
      const value = result.data;
      await i18next.changeLanguage(value.preference.language);

      const address =
        value.evmCompatibleAccounts.at(0)?.address ??
        value.emailAccounts.at(0)?.address ??
        value.googleAccounts.at(0)?.address ??
        "";
      const needWalletConnect = value.evmCompatibleAccounts.length === 0;
      setAuthState((prev) => ({
        ...prev,
        userId: value.id,
        address,
        isEmailAccount: Boolean(value.emailAccounts.length),
        accounts: value,
        needWalletConnect, // accounts 정보 요청 실패시 false 가정 처리
      }));
      setUser(result.data);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDaoMemberInfo = useCallback(
    async ({ subdomain }: { subdomain: string }) => {
      return getIsJoinedDao({ subdomain }).then(async (result) => {
        if (result.ok) {
          const { dao, kicked, member, isJoined, canEnter } = result.value;

          if (kicked) {
            await cietyAlert({
              body: t(
                `${ENameSpace.Common}:CommunityJoin.Error.ReJoinDaoNotAllowed`,
              ),
              buttonText: t(`${ENameSpace.Common}:CommunityJoin.Error.Ok`),
            });

            navigate(-1);
            return;
          }

          setIsGating(!isJoined && canEnter);
          setMember(member);
          setDao(dao);
          setAuthState((prev) => ({
            ...prev,
            daoId: dao.id,
            categoryId: dao.categoryId,
            subdomain,
          }));
          dispatch(setMarketplaceVisibility(dao.marketplaceVisibility));
          dispatch(setGlobalMode(dao.mode));
        } else {
          if (result.value === ECode.NotFound) {
            isErrorUrlRef.current = true;
            decodedPath2Ref.current = "404";
          } else {
            isErrorUrlRef.current = true;
          }
        }
      });
    },
    [decodedPath2Ref, dispatch, isErrorUrlRef, navigate, t],
  );

  const updateDaoId = useCallback((daoId: string) => {
    setAuthState((prev) => ({
      ...prev,
      daoId,
    }));
  }, []);

  const updateCategoryId = useCallback((categoryId: string) => {
    setAuthState((prev) => ({
      ...prev,
      categoryId,
    }));
  }, []);

  const authDispatch = useMemo<AuthDispatch>(
    () => ({
      handleOpenCommunityJoinModal,
      handleCloseCommunityJoinModal,
      updateAuthState,
      updateDaoMemberInfo,
      updateDaoId,
      updateCategoryId,
    }),
    [
      handleOpenCommunityJoinModal,
      handleCloseCommunityJoinModal,
      updateAuthState,
      updateDaoMemberInfo,
      updateDaoId,
      updateCategoryId,
    ],
  );

  useEffect(() => {
    const [, prefix, subdomain] =
      /(\/@)([^/.]*)(\/?)/.exec(location.pathname) ?? [];
    if (prefix === "/@") {
      /**
       * 영어/숫자만 커뮤니티 서브도메인 가능
       *
       * subdomain을 /@ciety한글 이렇게 입력시 `subdomain2` 에서는 ciety만 추출되어
       * `subdomain`와 비교로직 추가
       */
      const [, , subdomain2] =
        /(\/@)([a-zA-Z0-9-_]+)(\/?)/.exec(location.pathname) ?? [];

      if (subdomain && subdomain == subdomain2) {
        setAuthState((prev) => ({ ...prev, subdomain }));
      } else {
        isErrorUrlRef.current = true;
        decodedPath2Ref.current = "404";
        setIsDaoLoading(false);
      }
    } else {
      setIsDaoLoading(false);
    }

    updateAuthState().finally(() => setIsAuthLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.subdomain) {
      updateDaoMemberInfo({ subdomain: authState.subdomain }).finally(() =>
        setIsDaoLoading(false),
      );
    }
  }, [authState.subdomain, updateDaoMemberInfo]);

  // 해당 라인 아래부터 수동 라우팅 부분입니다.
  if (isLoginPath) {
    location.href = env.ROOT_REDIRECT_URL;
    return null;
  }

  // 인증 제외 path
  if (isAuthExcludedPath) {
    return isAuthLoading || isDaoLoading ? null : (
      <MainModule
        dao={dao}
        member={member ?? null}
        subdomain={dao?.subdomain ?? null}
        authState={authState}
        authDispatch={authDispatch}
      >
        {openCommunityJoinModal && (
          <DesktopCommunityJoinModal
            isOpen={openCommunityJoinModal}
            onClose={handleCloseCommunityJoinModal}
            isEmailAccount={authState.isEmailAccount}
            userId={authState.userId}
          />
        )}
        {children}
      </MainModule>
    );
  }

  if (isErrorUrlRef.current) {
    return (
      <ErrorPage code={isNotFoundPath ? "404" : decodedPath2Ref.current} />
    );
  }

  if (isAuthLoading || isDaoLoading) {
    return <Initializing />;
  }

  return (
    <MainModule
      dao={dao}
      member={member}
      subdomain={dao?.subdomain ?? null}
      authState={authState}
      authDispatch={authDispatch}
    >
      {children}
    </MainModule>
  );
}

export default AuthGuard;
